// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  pressed = 5,
  elses = 6,
  and = 7,
  or = 8,
  print = 9,
  forward = 10,
  turn = 11,
  color = 12,
  sleep = 13,
  is = 14,
  add = 15,
  from = 16,
  remove = 17,
  toList = 18,
  clear = 19,
  ins = 20,
  not_in = 21,
  repeat = 22,
  times = 23,
  fors = 24,
  to = 25,
  range = 26,
  call = 27,
  define = 28,
  returns = 29,
  _with = 30,
  Comment = 31,
  Program = 32,
  Command = 33,
  Assign = 34,
  Text = 35,
  ListAccess = 36,
  Number = 37,
  Expression = 39,
  String = 40,
  AssignList = 43,
  Comma = 44,
  Ask = 45,
  Clear = 46,
  Print = 47,
  Call = 48,
  Arguments = 49,
  Turtle = 50,
  Sleep = 54,
  Add = 55,
  Remove = 56,
  If = 57,
  Condition = 58,
  Else = 62,
  Repeat = 63,
  For = 64,
  Define = 65,
  Return = 66,
  ErrorInvalid = 67
