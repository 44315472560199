// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  pressed = 5,
  elses = 6,
  print = 7,
  forward = 8,
  turn = 9,
  color = 10,
  sleep = 11,
  is = 12,
  add = 13,
  from = 14,
  remove = 15,
  toList = 16,
  clear = 17,
  ins = 18,
  not_in = 19,
  repeat = 20,
  times = 21,
  Comment = 22,
  Program = 23,
  Command = 24,
  Assign = 25,
  Text = 26,
  Expression = 28,
  Int = 29,
  ListAccess = 32,
  AssignList = 33,
  Comma = 34,
  Ask = 35,
  String = 36,
  Clear = 37,
  Print = 38,
  Turtle = 39,
  Sleep = 43,
  Add = 44,
  Remove = 45,
  If = 46,
  Condition = 47,
  IfLessCommand = 51,
  Repeat = 52,
  ErrorInvalid = 53,
  Else = 54
