// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  print = 2,
  forward = 3,
  turn = 4,
  color = 5,
  sleep = 6,
  is = 7,
  Comment = 8,
  Program = 9,
  Command = 10,
  Assign = 11,
  Text = 12,
  Ask = 13,
  Print = 14,
  Turtle = 15,
  Sleep = 19,
  ErrorInvalid = 20
