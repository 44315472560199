// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  pressed = 5,
  elses = 6,
  print = 7,
  forward = 8,
  turn = 9,
  color = 10,
  sleep = 11,
  is = 12,
  add = 13,
  from = 14,
  remove = 15,
  toList = 16,
  clear = 17,
  ins = 18,
  not_in = 19,
  repeat = 20,
  times = 21,
  fors = 22,
  Comment = 23,
  Program = 24,
  Command = 25,
  Assign = 26,
  Text = 27,
  Expression = 29,
  Int = 30,
  ListAccess = 33,
  AssignList = 34,
  Comma = 35,
  Ask = 36,
  String = 37,
  Clear = 38,
  Print = 39,
  Turtle = 40,
  Sleep = 44,
  Add = 45,
  Remove = 46,
  If = 47,
  Condition = 48,
  Else = 52,
  Repeat = 53,
  For = 54,
  ErrorInvalid = 55
