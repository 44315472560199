// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser, LocalTokenGroup} from "@lezer/lr"
import {specializeKeyword, extendKeyword} from "./tokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "3nQYQPOOOOQO'#Df'#DfQYQPOOO!WQPO'#DlO!iQPO'#DeOOQO'#Dr'#DrO!tQPO'#DSO#PQPO'#DTOOQO'#Ds'#DsO#_QPO'#DVOOQO'#Dt'#DtO#jQPO'#DWOOQO'#Du'#DuO#uQPO'#DXOOQO'#DU'#DUOOQO'#Dv'#DvO$QQPO'#DYOOQO'#Dw'#DwO$cQPO'#DZOOQO'#Dy'#DyO$kQPO'#D[OOQO'#D{'#D{O$sQPO'#D]OOQO'#EP'#EPO${QPO'#DbOOQO'#EQ'#EQO%WQPO'#DcOOQO'#ES'#ESO%cQPO'#DdOOQO'#Cu'#CuQ!RQPO'#DgQ%kQPOOOOQO-E7d-E7dOOQO'#Dh'#DhO&fQPO,59bOOQO'#Cx'#CxO&tQPO,59bOOQO'#Dl'#DlOOQO-E7j-E7jOOQO-E7p-E7pO'PQPO'#DoOOQO'#Cy'#CyO(`QPO'#CyO(jOSO'#E_O(rOQO'#EcOOQO'#DR'#DROOQO'#Do'#DoO(zQPO,59oO)`QPO,59qOOQO-E7q-E7qOOQO,59q,59qO)tQPO,59rOOQO-E7r-E7rOOQO,59r,59rO*YQPO,59sOOQO-E7s-E7sOOQO,59s,59sO*nQPO,59tOOQO-E7t-E7tOOQO,59t,59tOOQO-E7u-E7uO+SQPO,59uOOQO-E7w-E7wO+XQPO,59vOOQO-E7y-E7yO+^QPO'#D_OOQO'#D^'#D^OOQO,59w,59wOOQO-E7}-E7}OOQO-E8O-E8OO+iQPO,59}OOQO-E8Q-E8QO+nQPO,5:OO+sQPO,5:ROOQO-E7e-E7eOOQO-E7f-E7fO,tQPO'#DiO-`QPO1G/UO-gQPO'#CyOOQO'#Di'#DiO-qQPO1G.|O.PQPO1G/UOOQO'#Dn'#DnO.XQPO1G/WOOQO'#C{'#C{OOQO'#C|'#C|O.jQPO,5:tO.jQPO,5:tOOQO'#Dj'#DjO.rQPO,59iOOOO'#Dp'#DpO/QOSO,5:yOOQO,5:y,5:yOOOO'#Dq'#DqO/YOQO,5:}OOQO,5:},5:}OOQO-E7m-E7mOOQO'#Dx'#DxO/bQPO1G/aOOQO'#Dz'#DzO/jQPO1G/bO/rQPO,59yOOQO'#D}'#D}O0WQPO,59zOOQO'#EO'#EOO0`QPO,59{OOQO'#ER'#ERO0hQPO1G/iO0sQPO1G/jO0{QPO'#DmO1TQPO7+$pOOQO-E7g-E7gOOQO-E7l-E7lO1`QPO7+$rOOQO1G0`1G0`O1tQPO1G0`OOQO-E7h-E7hOOQO'#Dk'#DkO2fQPO1G/TOOQO1G/T1G/TOOOO-E7n-E7nOOQO1G0e1G0eOOOO-E7o-E7oOOQO1G0i1G0iOOQO-E7v-E7vOOQO7+${7+${OOQO-E7x-E7xOOQO7+$|7+$|O2}QPO1G/eOOQO'#D|'#D|O3cQPO1G/eOOQO1G/e1G/eOOQO-E7{-E7{OOQO1G/f1G/fOOQO-E7|-E7|OOQO1G/g1G/gOOQO-E8P-E8POOQO7+%U7+%UO3nQPO,5:XOOQO,5:X,5:XOOQO-E7k-E7kOOQO-E7i-E7iOOQO-E7z-E7z",
  stateData: "4P~OgOS!yOS~OSeOUgOVVOWWOXYOY[OZ_O]aO_cOaTOdiOfkOkRO!zPO~O[qO!{sOk!`X!w!`X!z!`X~OkuO!w!XX!z!XX~OaTO!wvX!zvX~OkzOnyO#S{O#W|O~OWWOkzOnyO~OXYOkzOnyO~OY[OkzOnyO~OZ_OkzOnyO!w|X!z|X~O]aOk!_O~O_cOk!aO~OSeOk!cO~OUgO!w!UX!z!UX~OdiOk!hOn!hO~OfkOk!jO~OSeOUgOVVOWWOXYOY[OZ_O]aO_cOaTOdiOfkOkRO~OP!tO[qOk!pOn!oO~OP!tOk!pOn!oO~O!}!vO#O!vO#P!wO#Q!wOk!cXn!cX!w!cX!z!cX#S!cX#W!cX~OQ!zOkmXnmX!wmX!zmX!}mX#OmX#PmX#QmX~O#SmX#WmX~P'qO#T!|O#U#OO~O#X#PO#Y#RO~OkzOnyO#S{O#W|O!wwa!zwa~O!}!vO#O!vO#P!wO#Q!wO!wya!zya~O!}!vO#O!vO#P!wO#Q!wO!wza!zza~O!}!vO#O!vO#P!wO#Q!wO!w{a!z{a~O!}!vO#O!vO#P!wO#Q!wO!w|a!z|a~O`#TO~O^#VO~O[qOb#YOc#[O~Oe#^O~Ob#YO~O!zPOS!ZaU!ZaV!ZaW!ZaX!ZaY!ZaZ!Za]!Za_!Zaa!Zad!Zaf!Zak!Za!w!Za~O!}!vO#O!vO#P!wO#Q!wOk!]Xn!]X!w!]X!z!]X~Os#aO~P'tOk!`Xs!`X~P'qOkzOnyO!wji!zji~OkuOs#aO~OP!tOkzOnyO#S{O#W|O~OkyOnyO~OQ!zOR#iOk#kOn#kO~O#T!|O#U#mO~O#X#PO#Y#oO~O`#TOk#qO~O^#VOk#sO~OT#uO[qOkyOnyO#S{O#W|O~Ob#YOk#yO~Oc#[Ok#{O~Oe#^O!w!Vi!z!Vi~Ob#YOk#}O~OkuOn$PO~Os#aO!wrq!zrq~OkzOnyO#S{O#W|O!wtq!ztq~O!}!vO#O!vO#P!wO#Q!wOk!|in!|i!w!|i!z!|i#S!|i#W!|i~OR#iOkqinqi!wqi!zqi#Sqi#Wqi~O!}!vO#O!vO#P!wO#Q!wO!w!Ri!z!Ri~OT#uO!w!Ri!z!Ri~OkuOs!aa!w!aa!z!aa~Onk~",
  goto: "+O#WPPPPPPPPPPPPPPPPPPPPPPPPP#X#^P#c#fP$W$b$l#^P#^%T#^#^#^%^%^%^#^#^#^#^%c%f%f%f#^#^#^#^%i%s%z&U&]&d&j&{'S'Z'e'k'q'y(R(Z(c(k(s(y)R)X)a)g)q)w*P*X*_PPPPP*gPPPP*wPPP*wVnOQoVmOQoRtRWxV!P!u#eQ!QXQ!TZQ!W]Q!Z`U!nrt!rQ#f!xQ#g!yR#t#Xa!xx!Q!T!W!Z!n#g#ta!yx!Q!T!W!Z!n#g#tW!OV!P!u#eQ!SXQ!VZQ!Y]Q!]`V!qrt!rW!OV!P!u#eR#w#XV^OQoR!efR!dfQQOSpQ!kR!knSoOQR!loQrRS!mr#XR#X!cS!rrtR#c!rS!{z!pR#h!{Q#j!{R$R#jUSOQoUvS!s$OS!srtR$O#aS#b!o!sR$Q#bS!urtR#d!uQ!PVS#S!P#eR#e!uQ!}{R#l!}Q#Q|R#n#QUUOQoRwUUXOQoR!RXUZOQoR!UZU]OQoR!X]U`OQoR![`UbOQoR!^bQ#U!_R#p#UUdOQoR!`dQ#W!aR#r#WUfOQoR!bfQ#v#XR$S#vQ#Z!cQ#`!jT#x#Z#`Q#]!cR#z#]UhOQoR!fhUjOQoR!gjQ#_!hR#|#_UlOQoR!ilmyVXZ]`rt!P!r!u!x!y#X#eZ}V!P!u#X#e",
  nodeNames: "⚠ ask at random if pressed else print forward turn color sleep is add from remove toList clear in not_in repeat times for Comment Program Command Assign Text Op Expression Int Op Op ListAccess AssignList Comma Ask String Clear Print Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck Else Repeat For ErrorInvalid",
  maxTerm: 102,
  nodeProps: [
    ["group", 40,"turtle"]
  ],
  skippedNodes: [0,23],
  repeatNodeCount: 29,
  tokenData: "*x~R!^OY$}YZ&UZp$}pq&Zqr$}rs&`st&etw$}wx&|xz$}z{'R{|'W|}']}!O'b!O!P$}!P!Q'g!Q!R'l!R!S'l!S!T'l!T!U'l!U!V'l!V!W'l!W!X'l!X!Y'l!Y!Z'l!Z!['l![!_$}!_!`*s!`#Q$}#RBn$}BnBo']BoDf$}DfDg'lDgDh'lDhDi'lDiDj'lDjDk'lDkDl'lDlDm'lDmDn'lDnDo'lDoDp'lDpGl$}GlGm'lGmGn'lGnGo'lGoGp'lGpGq'lGqGr'lGrGs'lGsGt'lGtGu'lGuGv'lGv&FV$}&FV&FW']&FW;'S$};'S;=`&O<%l?Hb$}?Hb?Hc']?HcO$}~%S^k~OY$}Zp$}qr$}tw$}xz$}!O!P$}!Q!_$}!`#Q$}#RBn$}Bo&FV$}&FW;'S$};'S;=`&O<%l?Hb$}?HcO$}~&RP;=`<%l$}~&ZO!z~~&`O!y~~&eO#S~~&jSg~OY&eZ;'S&e;'S;=`&v<%lO&e~&yP;=`<%l&e~'RO#W~~'WO!}~~']O#P~~'bOs~~'gO#Q~~'lO#O~~'s!On~k~OY$}Zp$}qr$}tw$}xz$}!O!P$}!Q!R'l!R!S'l!S!T'l!T!U'l!U!V'l!V!W'l!W!X'l!X!Y'l!Y!Z'l!Z!['l![!_$}!`#Q$}#RBn$}BoDf$}DfDg'lDgDh'lDhDi'lDiDj'lDjDk'lDkDl'lDlDm'lDmDn'lDnDo'lDoDp'lDpGl$}GlGm'lGmGn'lGnGo'lGoGp'lGpGq'lGqGr'lGrGs'lGsGt'lGtGu'lGuGv'lGv&FV$}&FW;'S$};'S;=`&O<%l?Hb$}?HcO$}~*xO!{~",
  tokenizers: [2, new LocalTokenGroup("_~RQYZXwxX~^O#Y~~", 14, 101), new LocalTokenGroup("_~RQYZXrsX~^O#U~~", 14, 97)],
  topRules: {"Program":[0,24]},
  dynamicPrecedences: {"55":-10},
  specialized: [{term: 27, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 27, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 872
})
