// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  print = 1,
  is = 2,
  input = 3,
  sleep = 4,
  random = 5,
  forward = 6,
  turn = 7,
  color = 8,
  add = 9,
  remove = 10,
  from = 11,
  clear = 12,
  ifs = 13,
  elses = 14,
  and = 15,
  or = 16,
  pressed = 17,
  notIn = 18,
  ins = 19,
  repeat = 20,
  times = 21,
  range = 22,
  whiles = 23,
  def = 24,
  returns = 25,
  fors = 26,
  toList = 27,
  elif = 28,
  Comment = 29,
  Program = 30,
  Command = 31,
  Assign = 32,
  Text = 33,
  ListAccess = 34,
  Number = 36,
  Expression = 39,
  String = 40,
  AssignList = 43,
  Comma = 44,
  Input = 45,
  Arguments = 47,
  Call = 48,
  Clear = 50,
  Print = 51,
  Turtle = 52,
  Sleep = 56,
  Add = 57,
  Remove = 58,
  If = 59,
  Condition = 60,
  Else = 70,
  Repeat = 71,
  For = 72,
  Define = 73,
  Return = 74,
  While = 75,
  Elif = 76,
  ErrorInvalid = 77
