// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser, LocalTokenGroup} from "@lezer/lr"
import {specializeKeyword, extendKeyword} from "./tokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "3UQYQPOOOOQO'#Db'#DbQYQPOOO!QQPO'#DhO!fQPO'#D`OOQO'#Dn'#DnO!tQPO'#DPO#SQPO'#DQOOQO'#Do'#DoO#bQPO'#DSOOQO'#Dp'#DpO#mQPO'#DTOOQO'#Dq'#DqO#xQPO'#DUOOQO'#DR'#DROOQO'#Dr'#DrO$TQPO'#DVOOQO'#Ds'#DsO$iQPO'#DWOOQO'#Du'#DuO$qQPO'#DXOOQO'#Dw'#DwO$yQPO'#DYOOQO'#D{'#D{O%sQPO'#DaOOQO'#Cr'#CrQ{QPO'#DcQ%zQPOOOOQO-E7`-E7`OOQO'#Dd'#DdO&RQPO,59_OOQO'#Cu'#CuO&aQPO,59_OOQO'#Dh'#DhOOQO-E7f-E7fOOQO-E7l-E7lO&lQPO'#DkOOQO'#Cv'#CvO(RQPO'#CvO(]OSO'#EWO(eOQO'#E[OOQO'#DO'#DOOOQO'#Dk'#DkO(mQPO,59lO)UQPO,59nOOQO-E7m-E7mOOQO,59n,59nO)mQPO,59oOOQO-E7n-E7nOOQO,59o,59oO*UQPO,59pOOQO-E7o-E7oOOQO,59p,59pO*mQPO,59qOOQO-E7p-E7pOOQO,59q,59qOOQO-E7q-E7qO+UQPO,59rOOQO-E7s-E7sO+ZQPO,59sOOQO-E7u-E7uO+`QPO'#D[OOQO'#DZ'#DZO`QPO,59tO`QPO,59{OOQO'#D_'#D_OOQO-E7y-E7yOOQO,59{,59{O+kQPO,59}OOQO-E7a-E7aOOQO-E7b-E7bO,fQPO'#DeO-TQPO1G/RO-[QPO'#CvOOQO'#De'#DeO-fQPO1G.yO-wQPO1G/ROOQO'#Dj'#DjO.PQPO1G/TOOQO'#Cx'#CxOOQO'#Cy'#CyO.bQPO,5:mO.bQPO,5:mOOQO'#Df'#DfO.jQPO,59fOOOO'#Dl'#DlO.xOSO,5:rOOQO,5:r,5:rOOOO'#Dm'#DmO/QOQO,5:vOOQO,5:v,5:vOOQO-E7i-E7iOOQO'#Dt'#DtO/YQPO1G/^OOQO'#Dv'#DvO/bQPO1G/_O/jQPO,59vOOQO'#Dy'#DyO0OQPO,59wOOQO'#Dz'#DzO0WQPO,59xO`QPO1G/`O0`QPO1G/`OOQO1G/g1G/gO0kQPO'#DiO0sQPO7+$mOOQO-E7c-E7cOOQO-E7h-E7hO1RQPO7+$oOOQO1G0X1G0XO1jQPO1G0XOOQO-E7d-E7dOOQO'#Dg'#DgO2wQPO1G/QOOQO1G/Q1G/QOOOO-E7j-E7jOOQO1G0^1G0^OOOO-E7k-E7kOOQO1G0b1G0bOOQO-E7r-E7rOOQO7+$x7+$xOOQO-E7t-E7tOOQO7+$y7+$yO4TQPO1G/bOOQO'#Dx'#DxO4eQPO1G/bOOQO1G/b1G/bOOQO-E7w-E7wOOQO1G/c1G/cOOQO-E7x-E7xOOQO1G/d1G/dO4lQPO7+$zOOQO7+$z7+$zO4wQPO,5:TOOQO,5:T,5:TOOQO-E7g-E7gOOQO-E7e-E7eOOQO-E7v-E7vOOQO<<Hf<<Hf",
  stateData: "5]~OdOS!rOS~OSeOTgOVVOWWOXYOY[OZ_O]aO_cOaTOhRO!sPO~O[mO!toOh![X!p![X!s![XT![X~OhqO!p!SX!s!SXT!SX~OaTO!psX!ssXTsX~OhvOkuO!{wO#PxO~OWWOhvOkuO~OXYOhvOkuO~OY[OhvOkuO~OZ_OhvOkuO!pyX!syXTyX~O]aOh!ZO~O_cOh!]O~OSeOh!_O~OTgOVVOWWOXYOY[OZ_O]aO_cOaTOhRO~O!sPO~P%ROSeO~P%ROP!oO[mOh!kOk!jO~OP!oOh!kOk!jO~O!v!qO!w!qO!x!rO!y!rOh!_Xk!_X!p!_X!s!_X!{!_X#P!_XT!_X~OQ!uOhjXkjX!pjX!sjX!vjX!wjX!xjX!yjXTjX~O!{jX#PjX~P'aO!|!wO!}!yO~O#Q!zO#R!|O~OhvOkuO!{wO#PxO!pta!staTta~O!v!qO!w!qO!x!rO!y!rO!pva!svaTva~O!v!qO!w!qO!x!rO!y!rO!pwa!swaTwa~O!v!qO!w!qO!x!rO!y!rO!pxa!sxaTxa~O!v!qO!w!qO!x!rO!y!rO!pya!syaTya~O`#OO~O^#QO~O[mOb#TOc#VO~O!sPOS!VaT!VaV!VaW!VaX!VaY!VaZ!Va]!Va_!Vaa!Vah!Va!p!Va~O!v!qO!w!qO!x!rO!y!rOh!XXk!XX!p!XX!s!XXT!XX~Op#[O~P'dOh![Xp![X~P'aOhvOkuO!pgi!sgiTgi~OhqOp#[O~OP!oOhvOkuO!{wO#PxO~OhuOkuO~OQ!uOR#dOh#fOk#fO~O!|!wO!}#hO~O#Q!zO#R#jO~O`#OOh#lO~O^#QOh#nO~OU#pO[mOhuOkuO!{wO#PxO~Ob#TOh#tO~Oc#VOh#vO~OTgO!p|i!s|i~OhqOk#zO~Op#[O!poq!soqToq~OhvOkuO!{wO#PxO!pqq!sqqTqq~O!v!qO!w!qO!x!rO!y!rOh!uik!ui!p!ui!s!ui!{!ui#P!uiV!uiW!uiX!uiY!uiZ!ui]!ui_!uia!uiT!ui~OR#dOhnikni!pni!sni!{ni#PniTni~OV!OiW!OiX!OiY!OiZ!Oi]!Oi_!Oia!Oih!Oi!s!Oi~O!v!qO!w!qO!x!rO!y!rO~P3cOU#pO~P3cOTgO!p|q!s|q~OhqOp!]a!p!]a!s!]aT!]a~Okh~",
  goto: ",P#PPPPPPPPPPPPPPPPPPPPPPP#Q#VP#b#eP$V$a$k#VP#V%S#V#V#V%]%]%]#V#V#V%f%k%n%n%n%q#V%}&Y&l&s&}'U']'c'x(P(W(b(h(n(z)W)d)p)|*Y*`*l*r*z+Q+W+^PPPPP+hPPPP+xPPP+xVjOQkUiOQkX!ch!a!b#XRpRWtV{!p#`Q|XQ!PZQ!S]Q!V`U!inp!mQ#a!sQ#b!tR#o#Sa!st|!P!S!V!i#b#oa!tt|!P!S!V!i#b#oWzV{!p#`Q!OXQ!RZQ!U]Q!X`V!lnp!mWzV{!p#`R#r#S_^OQhk!a!b#XViOQkR!afR!`fQ!ehQ#Y!aQ#Z!bR#w#XUiOQkQ#x#YR$O#wQQOWlQ!b!f#XQ!bhQ!fjR#X!aSkOQR!gkQnRS!hn#SR#S!_S!mnpR#^!mS!vv!kR#c!vQ#e!vR#|#e^SOQhk!a!b#XUrS!n#yS!nnpR#y#[S#]!j!nR#{#]S!pnpR#_!pQ{VS!}{#`R#`!pQ!xwR#g!xQ!{xR#i!{^UOQhk!a!b#XRsU^XOQhk!a!b#XR}X^ZOQhk!a!b#XR!QZ^]OQhk!a!b#XR!T]^`OQhk!a!b#XR!W`^bOQhk!a!b#XR!YbQ#P!ZR#k#P^dOQhk!a!b#XR![dQ#R!]R#m#RUfOQkR!^fQ#q#SR#}#qQ#U!_R#s#UQ#W!_R#u#WYhOQk#Y#wR!dhmuVXZ]`np{!m!p!s!t#S#`ZyV{!p#S#`",
  nodeNames: "⚠ ask at random if else pressed print forward turn color sleep is add from remove toList clear in not_in Comment Program Command Assign Text Op Expression Int Op Op ListAccess AssignList Comma Ask String Clear Print Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck IfLessCommand ErrorInvalid Else",
  maxTerm: 95,
  nodeProps: [
    ["group", 37,"turtle"]
  ],
  skippedNodes: [0,20],
  repeatNodeCount: 26,
  tokenData: "*x~R!^OY$}YZ&UZp$}pq&Zqr$}rs&`st&etw$}wx&|xz$}z{'R{|'W|}']}!O'b!O!P$}!P!Q'g!Q!R'l!R!S'l!S!T'l!T!U'l!U!V'l!V!W'l!W!X'l!X!Y'l!Y!Z'l!Z!['l![!_$}!_!`*s!`#Q$}#RBn$}BnBo']BoDf$}DfDg'lDgDh'lDhDi'lDiDj'lDjDk'lDkDl'lDlDm'lDmDn'lDnDo'lDoDp'lDpGl$}GlGm'lGmGn'lGnGo'lGoGp'lGpGq'lGqGr'lGrGs'lGsGt'lGtGu'lGuGv'lGv&FV$}&FV&FW']&FW;'S$};'S;=`&O<%l?Hb$}?Hb?Hc']?HcO$}~%S^h~OY$}Zp$}qr$}tw$}xz$}!O!P$}!Q!_$}!`#Q$}#RBn$}Bo&FV$}&FW;'S$};'S;=`&O<%l?Hb$}?HcO$}~&RP;=`<%l$}~&ZO!s~~&`O!r~~&eO!{~~&jSd~OY&eZ;'S&e;'S;=`&v<%lO&e~&yP;=`<%l&e~'RO#P~~'WO!v~~']O!x~~'bOp~~'gO!y~~'lO!w~~'s!Ok~h~OY$}Zp$}qr$}tw$}xz$}!O!P$}!Q!R'l!R!S'l!S!T'l!T!U'l!U!V'l!V!W'l!W!X'l!X!Y'l!Y!Z'l!Z!['l![!_$}!`#Q$}#RBn$}BoDf$}DfDg'lDgDh'lDhDi'lDiDj'lDjDk'lDkDl'lDlDm'lDmDn'lDnDo'lDoDp'lDpGl$}GlGm'lGmGn'lGnGo'lGoGp'lGpGq'lGqGr'lGrGs'lGsGt'lGtGu'lGuGv'lGv&FV$}&FW;'S$};'S;=`&O<%l?Hb$}?HcO$}~*xO!t~",
  tokenizers: [2, new LocalTokenGroup("_~RQYZXwxX~^O#R~~", 14, 94), new LocalTokenGroup("_~RQYZXrsX~^O!}~~", 14, 90)],
  topRules: {"Program":[0,21]},
  dynamicPrecedences: {"50":-10},
  specialized: [{term: 24, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 24, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 930
})
