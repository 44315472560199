// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser, LocalTokenGroup} from "@lezer/lr"
import {specializeKeyword, extendKeyword} from "./tokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "4|QYQPOOOOQO'#Dh'#DhQYQPOOO!WQPO'#DnO!iQPO'#DgOOQO'#Dt'#DtO!tQPO'#DUOOQO'#Du'#DuO#PQPO'#DVOOQO'#Dv'#DvO#bQPO'#DXOOQO'#Dw'#DwO#mQPO'#DYOOQO'#Dx'#DxO#xQPO'#DZOOQO'#DW'#DWOOQO'#Dy'#DyO$TQPO'#D[OOQO'#Dz'#DzO$fQPO'#D]OOQO'#D|'#D|O$nQPO'#D^OOQO'#EO'#EOO$vQPO'#D_OOQO'#ES'#ESO%OQPO'#DdOOQO'#ET'#ETO%ZQPO'#DeOOQO'#EV'#EVO%fQPO'#DfOOQO'#Cw'#CwQ!RQPO'#DiQ%nQPOOOOQO-E7f-E7fOOQO'#Dj'#DjO&iQPO,59dOOQO'#Cz'#CzO&wQPO,59dOOQO'#Dn'#DnOOQO-E7l-E7lOOQO-E7r-E7rO'SQPO'#DqOOQO'#C{'#C{O(cQPO'#C{O(mOSO'#EdO(uOQO'#EhOOQO'#DT'#DTOOQO'#Dq'#DqO(}QPO,59qOOQO-E7s-E7sO)cQPO,59sOOQO-E7t-E7tOOQO,59s,59sO)wQPO,59tOOQO-E7u-E7uOOQO,59t,59tO*]QPO,59uOOQO-E7v-E7vOOQO,59u,59uO*qQPO,59vOOQO-E7w-E7wOOQO,59v,59vOOQO-E7x-E7xO+VQPO,59wOOQO-E7z-E7zO+[QPO,59xOOQO-E7|-E7|O+aQPO'#DaOOQO'#D`'#D`OOQO,59y,59yOOQO-E8Q-E8QOOQO-E8R-E8RO+lQPO,5:POOQO-E8T-E8TO+qQPO,5:QO+vQPO,5:TOOQO-E7g-E7gOOQO-E7h-E7hO,wQPO'#DkO-cQPO1G/WO-jQPO'#C{OOQO'#Dk'#DkO-tQPO1G/OO.SQPO1G/WOOQO'#Dp'#DpO.[QPO1G/YOOQO'#C}'#C}OOQO'#DO'#DOO.mQPO,5:yO.mQPO,5:yOOQO'#Dl'#DlO.uQPO,59kOOOO'#Dr'#DrO/TOSO,5;OOOQO,5;O,5;OOOOO'#Ds'#DsO/]OQO,5;SOOQO,5;S,5;SOOQO-E7o-E7oOOQO'#D{'#D{O/eQPO1G/cOOQO'#D}'#D}O/mQPO1G/dO/uQPO,59{OOQO'#EQ'#EQO0ZQPO,59|OOQO'#ER'#ERO0cQPO,59}OOQO'#EU'#EUO0kQPO1G/kO0vQPO1G/lO1RQPO'#DoO1ZQPO7+$rOOQO-E7i-E7iOOQO-E7n-E7nO1fQPO7+$tOOQO1G0e1G0eO1zQPO1G0eOOQO-E7j-E7jOOQO'#Dm'#DmO2lQPO1G/VOOQO1G/V1G/VOOOO-E7p-E7pOOQO1G0j1G0jOOOO-E7q-E7qOOQO1G0n1G0nOOQO-E7y-E7yOOQO7+$}7+$}OOQO-E7{-E7{OOQO7+%O7+%OO3TQPO1G/gOOQO'#EP'#EPO3iQPO1G/gOOQO1G/g1G/gOOQO-E8O-E8OOOQO1G/h1G/hOOQO-E8P-E8POOQO1G/i1G/iOOQO-E8S-E8SOOQO'#EW'#EWO3tQPO7+%WOOQO7+%W7+%WO4PQPO,5:ZOOQO,5:Z,5:ZOOQO-E7m-E7mOOQO-E7k-E7kOOQO-E7}-E7}OOQO-E8U-E8UO4_QPO<<HrOOQO'#EX'#EXO4dQPOAN>^OOQO-E8V-E8VOOQOG23xG23x",
  stateData: "4r~OiOS#OOS~OSfOUhOVVOWXOXZOY]OZ`O]bO_dOaTOdjOflOmRO#PPO~O[rO#QtOm!bX!|!bX#P!bX~OmvO!|!ZX#P!ZX~OaTO!|xX#PxX~OVVOm{OpzO#X|O#]}O~OWXOm{OpzO~OXZOm{OpzO~OY]Om{OpzO~OZ`Om{OpzO!|!OX#P!OX~O]bOm!aO~O_dOm!cO~OSfOm!eO~OUhO!|!WX#P!WX~OdjOm!jOp!jO~OflOm!lO~OSfOUhOVVOWXOXZOY]OZ`O]bO_dOaTOdjOflOmRO~OP!vO[rOm!rOp!qO~OP!vOm!rOp!qO~O#S!xO#T!xO#U!yO#V!yOm!eXp!eX!|!eX#P!eX#X!eX#]!eX~OQ!|OmoXpoX!|oX#PoX#SoX#ToX#UoX#VoX~O#XoX#]oX~P'tO#Y#OO#Z#QO~O#^#RO#_#TO~Om{OpzO#X|O#]}O!|ya#Pya~O#S!xO#T!xO#U!yO#V!yO!|{a#P{a~O#S!xO#T!xO#U!yO#V!yO!||a#P|a~O#S!xO#T!xO#U!yO#V!yO!|}a#P}a~O#S!xO#T!xO#U!yO#V!yO!|!Oa#P!Oa~O`#VO~O^#XO~O[rOb#[Oc#^O~Oe#`O~Ob#[O~O#PPOS!]aU!]aV!]aW!]aX!]aY!]aZ!]a]!]a_!]aa!]ad!]af!]am!]a!|!]a~O#S!xO#T!xO#U!yO#V!yOm!_Xp!_X!|!_X#P!_X~Ou#cO~P'wOm!bXu!bX~P'tOm{OpzO!|li#Pli~OmvOu#cO~OP!vOm{OpzO#X|O#]}O~OmzOpzO~OQ!|OR#kOm#mOp#mO~O#Y#OO#Z#oO~O#^#RO#_#qO~O`#VOm#sO~O^#XOm#uO~OT#wO[rOmzOpzO#X|O#]}O~Ob#[Om#{O~Oc#^Om#}O~Oe#`O!|!Xi#P!Xi~Ob#[Oh$POm$RO~OmvOp$TO~Ou#cO!|tq#Ptq~Om{OpzO#X|O#]}O!|vq#Pvq~O#S!xO#T!xO#U!yO#V!yOm#Rip#Ri!|#Ri#P#Ri#X#Ri#]#Ri~OR#kOmsipsi!|si#Psi#Xsi#]si~O#S!xO#T!xO#U!yO#V!yO!|!Ti#P!Ti~OT#wO!|!Ti#P!Ti~Oh$POm$YOp$YO~OmvOu!ca!|!ca#P!ca~Og$ZO~Og$ZOm$^Op$^O~Opm~",
  goto: "+i#]PPPPPPPPPPPPPPPPPPPPPPPPPPP#^#cP#h#kP$]$g$q#cP#c%Y#c#c#c%c%c%c#c#c#c#c%h%k%k%k#c#c#c#c%n%x&P&Z&b&i&o'Q'X'`'j'p'v(O(W(`(h(p(x)Q)W)`)f)n)t*O*U*^*f*l*t*zPPPPP+QPPPP+bPPP+bVoOQpVnOQpRuRWyW!Q!w#gQ!SYQ!V[Q!Y^Q!]aU!psu!tQ#h!zQ#i!{R#v#Za!zy!S!V!Y!]!p#i#va!{y!S!V!Y!]!p#i#vW!PW!Q!w#gQ!UYQ!X[Q![^Q!_aV!ssu!tW!PW!Q!w#gR#y#ZV_OQpR!ggR!fgQQOSqQ!mR!moSpOQR!npQsRS!os#ZR#Z!eS!tsuR#e!tS!}{!rR#j!}Q#l!}R$V#lUSOQpUwS!u$SS!usuR$S#cS#d!q!uR$U#dS!wsuR#f!wQ!QWS#U!Q#gR#g!wQ#P|R#n#PQ#S}R#p#SUUOQpRxUUWOQpR!RWUYOQpR!TYU[OQpR!W[U^OQpR!Z^UaOQpR!^aUcOQpR!`cQ#W!aR#r#WUeOQpR!beQ#Y!cR#t#YUgOQpR!dgQ#x#ZR$W#xQ#]!eQ#b!lT#z#]#bQ#_!eR#|#_UiOQpR!hiUkOQpR!ikQ#a!jR$O#aUmOQpR!kmQ$Q#bR$X$QQ$[$YR$]$[mzWY[^asu!Q!t!w!z!{#Z#gZ!OW!Q!w#Z#g",
  nodeNames: "⚠ ask at random if pressed else print forward turn color sleep is add from remove toList clear in not_in repeat times for to range Comment Program Command Assign Text Op Expression Int Op Op ListAccess AssignList Comma Ask String Clear Print Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck Else Repeat For ErrorInvalid",
  maxTerm: 107,
  nodeProps: [
    ["group", 42,"turtle"]
  ],
  skippedNodes: [0,25],
  repeatNodeCount: 32,
  tokenData: "*x~R!^OY$}YZ&UZp$}pq&Zqr$}rs&`st&etw$}wx&|xz$}z{'R{|'W|}']}!O'b!O!P$}!P!Q'g!Q!R'l!R!S'l!S!T'l!T!U'l!U!V'l!V!W'l!W!X'l!X!Y'l!Y!Z'l!Z!['l![!_$}!_!`*s!`#Q$}#RBn$}BnBo']BoDf$}DfDg'lDgDh'lDhDi'lDiDj'lDjDk'lDkDl'lDlDm'lDmDn'lDnDo'lDoDp'lDpGl$}GlGm'lGmGn'lGnGo'lGoGp'lGpGq'lGqGr'lGrGs'lGsGt'lGtGu'lGuGv'lGv&FV$}&FV&FW']&FW;'S$};'S;=`&O<%l?Hb$}?Hb?Hc']?HcO$}~%S^m~OY$}Zp$}qr$}tw$}xz$}!O!P$}!Q!_$}!`#Q$}#RBn$}Bo&FV$}&FW;'S$};'S;=`&O<%l?Hb$}?HcO$}~&RP;=`<%l$}~&ZO#P~~&`O#O~~&eO#X~~&jSi~OY&eZ;'S&e;'S;=`&v<%lO&e~&yP;=`<%l&e~'RO#]~~'WO#S~~']O#U~~'bOu~~'gO#V~~'lO#T~~'s!Op~m~OY$}Zp$}qr$}tw$}xz$}!O!P$}!Q!R'l!R!S'l!S!T'l!T!U'l!U!V'l!V!W'l!W!X'l!X!Y'l!Y!Z'l!Z!['l![!_$}!`#Q$}#RBn$}BoDf$}DfDg'lDgDh'lDhDi'lDiDj'lDjDk'lDkDl'lDlDm'lDmDn'lDnDo'lDoDp'lDpGl$}GlGm'lGmGn'lGnGo'lGoGp'lGpGq'lGqGr'lGrGs'lGsGt'lGtGu'lGuGv'lGv&FV$}&FW;'S$};'S;=`&O<%l?Hb$}?HcO$}~*xO#Q~",
  tokenizers: [2, new LocalTokenGroup("_~RQYZXwxX~^O#_~~", 14, 106), new LocalTokenGroup("_~RQYZXrsX~^O#Z~~", 14, 102)],
  topRules: {"Program":[0,26]},
  dynamicPrecedences: {"57":-10},
  specialized: [{term: 29, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 29, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 905
})
