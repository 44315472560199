// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  print = 4,
  forward = 5,
  turn = 6,
  color = 7,
  sleep = 8,
  is = 9,
  add = 10,
  from = 11,
  remove = 12,
  toList = 13,
  clear = 14,
  Comment = 15,
  Program = 16,
  Command = 17,
  Assign = 18,
  Text = 19,
  ListAccess = 20,
  AssignList = 21,
  Comma = 22,
  Ask = 23,
  String = 24,
  Clear = 25,
  Print = 26,
  Turtle = 27,
  Sleep = 31,
  Add = 32,
  Remove = 33,
  ErrorInvalid = 34
