// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  pressed = 5,
  elses = 6,
  and = 7,
  or = 8,
  is = 9,
  _while = 10,
  print = 11,
  forward = 12,
  turn = 13,
  color = 14,
  sleep = 15,
  add = 16,
  from = 17,
  remove = 18,
  toList = 19,
  clear = 20,
  ins = 21,
  not_in = 22,
  repeat = 23,
  times = 24,
  fors = 25,
  to = 26,
  range = 27,
  call = 28,
  define = 29,
  returns = 30,
  _with = 31,
  Comment = 32,
  Program = 33,
  Command = 34,
  Assign = 35,
  Text = 36,
  ListAccess = 37,
  Number = 38,
  Expression = 40,
  String = 41,
  AssignList = 44,
  Comma = 45,
  Ask = 46,
  Clear = 47,
  Print = 48,
  Call = 49,
  Arguments = 50,
  Turtle = 51,
  Sleep = 55,
  Add = 56,
  Remove = 57,
  If = 58,
  Condition = 59,
  Else = 69,
  Repeat = 70,
  For = 71,
  Define = 72,
  Return = 73,
  While = 74,
  ErrorInvalid = 75
