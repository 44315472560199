// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  pressed = 5,
  elses = 6,
  print = 7,
  forward = 8,
  turn = 9,
  color = 10,
  sleep = 11,
  is = 12,
  add = 13,
  from = 14,
  remove = 15,
  toList = 16,
  clear = 17,
  ins = 18,
  not_in = 19,
  repeat = 20,
  times = 21,
  fors = 22,
  to = 23,
  range = 24,
  call = 25,
  define = 26,
  returns = 27,
  _with = 28,
  Comment = 29,
  Program = 30,
  Command = 31,
  Assign = 32,
  Text = 33,
  ListAccess = 34,
  Number = 35,
  Expression = 37,
  String = 38,
  AssignList = 41,
  Comma = 42,
  Ask = 43,
  Clear = 44,
  Print = 45,
  Call = 46,
  Arguments = 47,
  Turtle = 48,
  Sleep = 52,
  Add = 53,
  Remove = 54,
  If = 55,
  Condition = 56,
  Else = 60,
  Repeat = 61,
  For = 62,
  Define = 63,
  Return = 64,
  ErrorInvalid = 65
