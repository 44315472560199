// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  print = 4,
  forward = 5,
  turn = 6,
  color = 7,
  sleep = 8,
  is = 9,
  add = 10,
  from = 11,
  remove = 12,
  toList = 13,
  Comment = 14,
  Program = 15,
  Command = 16,
  Assign = 17,
  Text = 18,
  ListAccess = 19,
  AssignList = 20,
  Comma = 21,
  Ask = 22,
  Print = 23,
  Turtle = 24,
  Sleep = 28,
  Add = 29,
  Remove = 30,
  ErrorInvalid = 31
