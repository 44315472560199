// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser, LocalTokenGroup} from "@lezer/lr"
import {specializeKeyword, extendKeyword} from "./tokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "2xQYQPOOOOQO'#Dd'#DdQYQPOOO!TQPO'#DjO!fQPO'#DcOOQO'#Dp'#DpO!qQPO'#DRO!|QPO'#DSOOQO'#Dq'#DqO#[QPO'#DUOOQO'#Dr'#DrO#gQPO'#DVOOQO'#Ds'#DsO#rQPO'#DWOOQO'#DT'#DTOOQO'#Dt'#DtO#}QPO'#DXOOQO'#Du'#DuO$`QPO'#DYOOQO'#Dw'#DwO$hQPO'#DZOOQO'#Dy'#DyO$pQPO'#D[OOQO'#D}'#D}O$xQPO'#DaOOQO'#EO'#EOO%TQPO'#DbOOQO'#Ct'#CtQ!OQPO'#DeQ%`QPOOOOQO-E7b-E7bOOQO'#Df'#DfO&WQPO,59aOOQO'#Cw'#CwO&fQPO,59aOOQO'#Dj'#DjOOQO-E7h-E7hOOQO-E7n-E7nO&qQPO'#DmOOQO'#Cx'#CxO(QQPO'#CxO([OSO'#E[O(dOQO'#E`OOQO'#DQ'#DQOOQO'#Dm'#DmO(lQPO,59nO)QQPO,59pOOQO-E7o-E7oOOQO,59p,59pO)fQPO,59qOOQO-E7p-E7pOOQO,59q,59qO)zQPO,59rOOQO-E7q-E7qOOQO,59r,59rO*`QPO,59sOOQO-E7r-E7rOOQO,59s,59sOOQO-E7s-E7sO*tQPO,59tOOQO-E7u-E7uO*yQPO,59uOOQO-E7w-E7wO+OQPO'#D^OOQO'#D]'#D]OOQO,59v,59vOOQO-E7{-E7{OOQO-E7|-E7|O+ZQPO,59|O+`QPO,5:POOQO-E7c-E7cOOQO-E7d-E7dO,^QPO'#DgO,xQPO1G/TO-PQPO'#CxOOQO'#Dg'#DgO-ZQPO1G.{O-iQPO1G/TOOQO'#Dl'#DlO-qQPO1G/VOOQO'#Cz'#CzOOQO'#C{'#C{O.SQPO,5:qO.SQPO,5:qOOQO'#Dh'#DhO.[QPO,59hOOOO'#Dn'#DnO.jOSO,5:vOOQO,5:v,5:vOOOO'#Do'#DoO.rOQO,5:zOOQO,5:z,5:zOOQO-E7k-E7kOOQO'#Dv'#DvO.zQPO1G/`OOQO'#Dx'#DxO/SQPO1G/aO/[QPO,59xOOQO'#D{'#D{O/pQPO,59yOOQO'#D|'#D|O/xQPO,59zOOQO'#EP'#EPO0QQPO1G/hO0]QPO'#DkO0eQPO7+$oOOQO-E7e-E7eOOQO-E7j-E7jO0pQPO7+$qOOQO1G0]1G0]O1UQPO1G0]OOQO-E7f-E7fOOQO'#Di'#DiO1vQPO1G/SOOQO1G/S1G/SOOOO-E7l-E7lOOQO1G0b1G0bOOOO-E7m-E7mOOQO1G0f1G0fOOQO-E7t-E7tOOQO7+$z7+$zOOQO-E7v-E7vOOQO7+${7+${O2_QPO1G/dOOQO'#Dz'#DzO2sQPO1G/dOOQO1G/d1G/dOOQO-E7y-E7yOOQO1G/e1G/eOOQO-E7z-E7zOOQO1G/f1G/fOOQO-E7}-E7}O3OQPO,5:VOOQO,5:V,5:VOOQO-E7i-E7iOOQO-E7g-E7gOOQO-E7x-E7x",
  stateData: "3a~OfOS!vOS~OSeOUgOVVOWWOXYOY[OZ_O]aO_cOaTOdiOjRO!wPO~O[oO!xqOj!^X!t!^X!w!^X~OjsO!t!VX!w!VX~OaTO!tuX!wuX~OjxOmwO#PyO#TzO~OWWOjxOmwO~OXYOjxOmwO~OY[OjxOmwO~OZ_OjxOmwO!t{X!w{X~O]aOj!]O~O_cOj!_O~OSeOj!aO~OUgO!t!TX!w!TX~OdiOj!fOm!fO~OSeOUgOVVOWWOXYOY[OZ_O]aO_cOaTOdiOjRO~OP!pO[oOj!lOm!kO~OP!pOj!lOm!kO~O!z!rO!{!rO!|!sO!}!sOj!aXm!aX!t!aX!w!aX#P!aX#T!aX~OQ!vOjlXmlX!tlX!wlX!zlX!{lX!|lX!}lX~O#PlX#TlX~P'cO#Q!xO#R!zO~O#U!{O#V!}O~OjxOmwO#PyO#TzO!tva!wva~O!z!rO!{!rO!|!sO!}!sO!txa!wxa~O!z!rO!{!rO!|!sO!}!sO!tya!wya~O!z!rO!{!rO!|!sO!}!sO!tza!wza~O!z!rO!{!rO!|!sO!}!sO!t{a!w{a~O`#PO~O^#RO~O[oOb#UOc#WO~Oe#YO~O!wPOS!XaU!XaV!XaW!XaX!XaY!XaZ!Xa]!Xa_!Xaa!Xad!Xaj!Xa!t!Xa~O!z!rO!{!rO!|!sO!}!sOj!ZXm!ZX!t!ZX!w!ZX~Or#[O~P'fOj!^Xr!^X~P'cOjxOmwO!tii!wii~OjsOr#[O~OP!pOjxOmwO#PyO#TzO~OjwOmwO~OQ!vOR#dOj#fOm#fO~O#Q!xO#R#hO~O#U!{O#V#jO~O`#POj#lO~O^#ROj#nO~OT#pO[oOjwOmwO#PyO#TzO~Ob#UOj#tO~Oc#WOj#vO~Oe#YO!t!Ui!w!Ui~OjsOm#yO~Or#[O!tqq!wqq~OjxOmwO#PyO#TzO!tsq!wsq~O!z!rO!{!rO!|!sO!}!sOj!yim!yi!t!yi!w!yi#P!yi#T!yi~OR#dOjpimpi!tpi!wpi#Ppi#Tpi~O!z!rO!{!rO!|!sO!}!sO!t!Qi!w!Qi~OT#pO!t!Qi!w!Qi~OjsOr!_a!t!_a!w!_a~Omj~",
  goto: "*o#TPPPPPPPPPPPPPPPPPPPPPPPP#U#ZP#`#cP$T$_$i#ZP#Z%Q#Z#Z#Z%Z%Z%Z#Z#Z#Z#Z%`%c%c%c#Z#Z#Z%f%p%w&R&Y&a&g&x'P'W'b'h'n'v(O(W(`(h(p(v)O)U)^)d)j)p)x*QPPPPP*WPPPP*hPPP*hVlOQmVkOQmRrRWvV}!q#`Q!OXQ!RZQ!U]Q!X`U!jpr!nQ#a!tQ#b!uR#o#Ta!tv!O!R!U!X!j#b#oa!uv!O!R!U!X!j#b#oW|V}!q#`Q!QXQ!TZQ!W]Q!Z`V!mpr!nW|V}!q#`R#r#TV^OQmR!cfR!bfQQOSnQ!gR!glSmOQR!hmQpRS!ip#TR#T!aS!nprR#^!nS!wx!lR#c!wQ#e!wR#{#eUSOQmUtS!o#xS!oprR#x#[S#]!k!oR#z#]S!qprR#_!qQ}VS#O}#`R#`!qQ!yyR#g!yQ!|zR#i!|UUOQmRuUUXOQmR!PXUZOQmR!SZU]OQmR!V]U`OQmR!Y`UbOQmR![bQ#Q!]R#k#QUdOQmR!^dQ#S!_R#m#SUfOQmR!`fQ#q#TR#|#qQ#V!aR#s#VQ#X!aR#u#XUhOQmR!dhUjOQmR!ejQ#Z!fR#w#ZmwVXZ]`pr}!n!q!t!u#T#`Z{V}!q#T#`",
  nodeNames: "⚠ ask at random if pressed else print forward turn color sleep is add from remove toList clear in not_in repeat times Comment Program Command Assign Text Op Expression Int Op Op ListAccess AssignList Comma Ask String Clear Print Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck Else Repeat ErrorInvalid",
  maxTerm: 99,
  nodeProps: [
    ["group", 39,"turtle"]
  ],
  skippedNodes: [0,22],
  repeatNodeCount: 28,
  tokenData: "*x~R!^OY$}YZ&UZp$}pq&Zqr$}rs&`st&etw$}wx&|xz$}z{'R{|'W|}']}!O'b!O!P$}!P!Q'g!Q!R'l!R!S'l!S!T'l!T!U'l!U!V'l!V!W'l!W!X'l!X!Y'l!Y!Z'l!Z!['l![!_$}!_!`*s!`#Q$}#RBn$}BnBo']BoDf$}DfDg'lDgDh'lDhDi'lDiDj'lDjDk'lDkDl'lDlDm'lDmDn'lDnDo'lDoDp'lDpGl$}GlGm'lGmGn'lGnGo'lGoGp'lGpGq'lGqGr'lGrGs'lGsGt'lGtGu'lGuGv'lGv&FV$}&FV&FW']&FW;'S$};'S;=`&O<%l?Hb$}?Hb?Hc']?HcO$}~%S^j~OY$}Zp$}qr$}tw$}xz$}!O!P$}!Q!_$}!`#Q$}#RBn$}Bo&FV$}&FW;'S$};'S;=`&O<%l?Hb$}?HcO$}~&RP;=`<%l$}~&ZO!w~~&`O!v~~&eO#P~~&jSf~OY&eZ;'S&e;'S;=`&v<%lO&e~&yP;=`<%l&e~'RO#T~~'WO!z~~']O!|~~'bOr~~'gO!}~~'lO!{~~'s!Om~j~OY$}Zp$}qr$}tw$}xz$}!O!P$}!Q!R'l!R!S'l!S!T'l!T!U'l!U!V'l!V!W'l!W!X'l!X!Y'l!Y!Z'l!Z!['l![!_$}!`#Q$}#RBn$}BoDf$}DfDg'lDgDh'lDhDi'lDiDj'lDjDk'lDkDl'lDlDm'lDmDn'lDnDo'lDoDp'lDpGl$}GlGm'lGmGn'lGnGo'lGoGp'lGpGq'lGqGr'lGrGs'lGsGt'lGtGu'lGuGv'lGv&FV$}&FW;'S$};'S;=`&O<%l?Hb$}?HcO$}~*xO!x~",
  tokenizers: [2, new LocalTokenGroup("_~RQYZXwxX~^O#V~~", 14, 98), new LocalTokenGroup("_~RQYZXrsX~^O#R~~", 14, 94)],
  topRules: {"Program":[0,23]},
  dynamicPrecedences: {"53":-10},
  specialized: [{term: 26, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 26, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 842
})
