// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  print = 1,
  forward = 2,
  turn = 3,
  echo = 4,
  ask = 5,
  color = 6,
  Comment = 7,
  Program = 8,
  Command = 9,
  Print = 10,
  Text = 11,
  Ask = 12,
  Echo = 13,
  Turtle = 14,
  ErrorInvalid = 18
