// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser, LocalTokenGroup} from "@lezer/lr"
import {specializeKeyword, extendKeyword} from "./tokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "1^QYQPOOOOQO'#D]'#D]QYQPOOO!QQPO'#D`O!cQPO'#DZOOQO'#Dh'#DhO!qQPO'#CzOOQO'#Di'#DiO#PQPO'#C{OOQO'#Dj'#DjO#_QPO'#C}OOQO'#Dk'#DkO#gQPO'#DOOOQO'#Dl'#DlO#oQPO'#DPOOQO'#C|'#C|OOQO'#Dm'#DmO#wQPO'#DQOOQO'#Dn'#DnO$YQPO'#DROOQO'#Dp'#DpO$bQPO'#DSOOQO'#Dr'#DrO$jQPO'#DTOOQO'#Dv'#DvO%dQPO'#D[OOQO'#Cr'#CrQ{QPO'#D^Q%kQPOOOOQO-E7Z-E7ZOOQO'#D_'#D_O%rQPO,59_OOQO'#D`'#D`OOQO-E7^-E7^OOQO-E7f-E7fO%}QPO'#DeO&fOSO'#D{O&nOQO'#EPOOQO'#Cy'#CyOOQO'#De'#DeO&vQPO,59gOOQO-E7g-E7gO'[QPO,59iOOQO-E7h-E7hOOQO,59i,59iO'jQPO,59jOOQO-E7i-E7iOOQO,59j,59jO'xQPO,59kOOQO-E7j-E7jOOQO,59k,59kO(WQPO,59lOOQO-E7k-E7kOOQO,59l,59lOOQO-E7l-E7lO(fQPO,59mOOQO-E7n-E7nO(kQPO,59nOOQO-E7p-E7pO(pQPO'#DVOOQO'#DU'#DUO`QPO,59oO`QPO,59vOOQO'#DY'#DYOOQO-E7t-E7tOOQO,59v,59vO({QPO,59xOOQO-E7[-E7[OOQO-E7]-E7]O)vQPO'#D`O*[QPO1G.yOOQO1G.y1G.yOOQO'#Dd'#DdO*mQPO1G/OOOQO'#Da'#DaO*{QPO,59aOOOO'#Df'#DfO+WOSO,5:gOOQO,5:g,5:gOOOO'#Dg'#DgO+`OQO,5:kOOQO,5:k,5:kOOQO-E7c-E7cOOQO'#Do'#DoO+hQPO1G/XOOQO'#Dq'#DqO+pQPO1G/YO+xQPO,59qOOQO'#Dt'#DtO,ZQPO,59rOOQO'#Du'#DuO,cQPO,59sO`QPO1G/ZO,kQPO1G/ZOOQO1G/b1G/bO,vQPO'#DcO,{QPO7+$hOOQO-E7b-E7bO-ZQPO7+$jOOQO-E7_-E7_OOQO'#Db'#DbO-oQPO1G.{OOQO1G.{1G.{OOOO-E7d-E7dOOQO1G0R1G0ROOOO-E7e-E7eOOQO1G0V1G0VOOQO-E7m-E7mOOQO7+$s7+$sOOQO-E7o-E7oOOQO7+$t7+$tOOQO'#Ds'#DsO.WQPO1G/]OOQO1G/]1G/]OOQO-E7r-E7rOOQO1G/^1G/^OOQO-E7s-E7sOOQO1G/_1G/_O.{QPO7+$uOOQO7+$u7+$uO/WQPO,59}OOQO-E7a-E7aOOQO-E7`-E7`OOQO-E7q-E7qOOQO<<Ha<<Ha",
  stateData: "/i~OdOS!mOS~OSfOThOVVOWXOXZOY]OZ`O]bO_dOaTOhRO!nPO~O[nOh!SX!k!SX!n!SXT!SX~OhpO!k}X!n}XT}X~OaTO!knX!nnXTnX~OVVOhsO!ptO!tuO~OWXOhzO~OXZOh}O~OY]Oh!QO~OZ`Oh!TO!ktX!ntXTtX~O]bOh!XO~O_dOh!ZO~OSfOh!]O~OThOVVOWXOXZOY]OZ`O]bO_dOaTOhRO~O!nPO~P$rOSfO~P$rOP!jO[nOh!gO~OQ!lOh!XX!k!XX!n!XX!p!XX!t!XXT!XX~O!q!nO!r!pO~O!u!qO!v!sO~OhsO!ptO!tuO!koa!noaToa~OQ!lO!kqa!nqaTqa~OQ!lO!kra!nraTra~OQ!lO!ksa!nsaTsa~OQ!lO!kta!ntaTta~O`!uO~O^!wO~O[nOb!zOc!|O~O!nPOS!QaT!QaV!QaW!QaX!QaY!QaZ!Qa]!Qa_!Qaa!Qah!Qa!k!Qa~OQ!lOh!SXk!SX!k!SX!n!SXT!SX~OhpOk#RO!kgi!ngiTgi~OP!jOhsO!ptO!tuO~OQ!lOR#WOh#YO~O!q!nO!r#[O~O!u!qO!v#^O~O`!uOh#`O~O^!wOh#bO~OU#cO[nOh#eO!ptO!tuO~Ob!zOh#gO~Oc!|Oh#iO~OThO!kwi!nwi~OhpO~Ok#RO!kjq!njqTjq~OhsO!ptO!tuO!klq!nlqTlq~OR#WOhii!kii!nii!pii!tiiTii~OU#cOVyiWyiXyiYyiZyi]yi_yiayihyi!nyi~OThO!kwq!nwq~OhpOk!Va!k!Va!n!VaT!Va~O",
  goto: "*_!tPPPPPPPPPPPPPPPPPPPPPP!u!zP#V!zP!z#l!z!z!z#u#u#u!z!z!z$O$T$W$W$W$Z!z$g$r%U%]%g%{&W&^&d&j&t&z'Q'^'j'v(S(`(l(x)O)[)b)j)p)v)|PPPP*WPPP*WVkOQlUjOQlX!ai!_!`#OWwWx!k#UQ|YQ!P[Q!S^Q!VaR!ioWwWx!k#UR#e!y__OQil!_!`#OVjOQlR!_gR!^gQ!ciQ#P!_Q#Q!`R#j#OUjOQlQ#k#PR#p#jQQOWmQ!`!d#OQ!`iQ!dkR#O!_SlOQR!elQoRS!fo!yR!y!]^SOQil!_!`#OUqS!h#lQ!hoR#l#R[!msz}!Q!T!gR#V!mQ#X!mR#n#XQ#S!hR#m#SQ!koR#T!kQxWS!tx#UR#U!kQ!otR#Z!oQ!ruR#]!r^UOQil!_!`#ORrU^WOQil!_!`#ORyW^YOQil!_!`#OR{Y^[OQil!_!`#OR!O[^^OQil!_!`#OR!R^^aOQil!_!`#OR!Ua^cOQil!_!`#OR!WcQ!v!XR#_!v^eOQil!_!`#OR!YeQ!x!ZR#a!xUgOQlR![gQ#d!yR#o#dQ!{!]R#f!{Q!}!]R#h!}YiOQl#P#jR!biZvWx!k!y#U",
  nodeNames: "⚠ ask at random if else pressed print forward turn color sleep is add from remove toList clear in not_in Comment Program Command Assign Text ListAccess AssignList Comma Ask String Clear Print Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck IfLessCommand ErrorInvalid Else",
  maxTerm: 84,
  nodeProps: [
    ["group", 32,"turtle"]
  ],
  skippedNodes: [0,20],
  repeatNodeCount: 26,
  tokenData: "$g~ReOY!dYZ#eZp!dpq#jqr!drs#ost#ttw!dwx$]x|!d|}$b}#Q!d#RBn!dBnBo$bBo&FV!d&FV&FW$b&FW;'S!d;'S;=`#_<%l?Hb!d?Hb?Hc$b?HcO!d~!i[h~OY!dZp!dqr!dtw!dx|!d}#Q!d#RBn!dBo&FV!d&FW;'S!d;'S;=`#_<%l?Hb!d?HcO!d~#bP;=`<%l!d~#jO!n~~#oO!m~~#tO!p~~#ySd~OY#tZ;'S#t;'S;=`$V<%lO#t~$YP;=`<%l#t~$bO!t~~$gOk~",
  tokenizers: [2, new LocalTokenGroup("_~RQYZXwxX~^O!v~~", 14, 83), new LocalTokenGroup("_~RQYZXrsX~^O!r~~", 14, 79)],
  topRules: {"Program":[0,21]},
  dynamicPrecedences: {"45":-10},
  specialized: [{term: 24, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 24, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 0
})
