// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser, LocalTokenGroup} from "@lezer/lr"
import {specializeKeyword, extendKeyword} from "./tokens"
export const parser = LRParser.deserialize({
  version: 14,
  states: "4WQYQPOOOOQO'#De'#DeQYQPOOO!TQPO'#DkO!iQPO'#DcOOQO'#Dq'#DqO!wQPO'#DRO#VQPO'#DSOOQO'#Dr'#DrO#eQPO'#DUOOQO'#Ds'#DsO#pQPO'#DVOOQO'#Dt'#DtO#{QPO'#DWOOQO'#DT'#DTOOQO'#Du'#DuO$WQPO'#DXOOQO'#Dv'#DvO$lQPO'#DYOOQO'#Dx'#DxO$tQPO'#DZOOQO'#Dz'#DzO$|QPO'#D[OOQO'#EO'#EOO%UQPO'#DbOOQO'#EQ'#EQO&UQPO'#DdOOQO'#Ct'#CtQ!OQPO'#DfQ&]QPOOOOQO-E7c-E7cOOQO'#Dg'#DgO&dQPO,59aOOQO'#Cw'#CwO&rQPO,59aOOQO'#Dk'#DkOOQO-E7i-E7iOOQO-E7o-E7oO&}QPO'#DnOOQO'#Cx'#CxO(dQPO'#CxO(nOSO'#E]O(vOQO'#EaOOQO'#DQ'#DQOOQO'#Dn'#DnO)OQPO,59nO)gQPO,59pOOQO-E7p-E7pOOQO,59p,59pO*OQPO,59qOOQO-E7q-E7qOOQO,59q,59qO*gQPO,59rOOQO-E7r-E7rOOQO,59r,59rO+OQPO,59sOOQO-E7s-E7sOOQO,59s,59sOOQO-E7t-E7tO+gQPO,59tOOQO-E7v-E7vO+lQPO,59uOOQO-E7x-E7xO+qQPO'#D^OOQO'#D]'#D]O`QPO,59vOOQO-E7|-E7|O+|QPO,59|O`QPO,5:OOOQO'#Da'#DaOOQO-E8O-E8OOOQO,5:O,5:OO,RQPO,5:QOOQO-E7d-E7dOOQO-E7e-E7eO-PQPO'#DhO-nQPO1G/TO-uQPO'#CxOOQO'#Dh'#DhO.PQPO1G.{O.bQPO1G/TOOQO'#Dm'#DmO.jQPO1G/VOOQO'#Cz'#CzOOQO'#C{'#C{O.{QPO,5:rO.{QPO,5:rOOQO'#Di'#DiO/TQPO,59hOOOO'#Do'#DoO/cOSO,5:wOOQO,5:w,5:wOOOO'#Dp'#DpO/kOQO,5:{OOQO,5:{,5:{OOQO-E7l-E7lOOQO'#Dw'#DwO/sQPO1G/`OOQO'#Dy'#DyO/{QPO1G/aO0TQPO,59xOOQO'#D|'#D|O0iQPO,59yOOQO'#D}'#D}O0qQPO,59zO`QPO1G/bO0yQPO1G/bOOQO'#EP'#EPO1UQPO1G/hOOQO1G/j1G/jO1fQPO'#DlO1nQPO7+$oOOQO-E7f-E7fOOQO-E7k-E7kO1|QPO7+$qOOQO1G0^1G0^O2eQPO1G0^OOQO-E7g-E7gOOQO'#Dj'#DjO3uQPO1G/SOOQO1G/S1G/SOOOO-E7m-E7mOOQO1G0c1G0cOOOO-E7n-E7nOOQO1G0g1G0gOOQO-E7u-E7uOOQO7+$z7+$zOOQO-E7w-E7wOOQO7+${7+${O5UQPO1G/dOOQO'#D{'#D{O5fQPO1G/dOOQO1G/d1G/dOOQO-E7z-E7zOOQO1G/e1G/eOOQO-E7{-E7{OOQO1G/f1G/fO5mQPO7+$|OOQO7+$|7+$|OOQO-E7}-E7}OOQO7+%S7+%SO5xQPO,5:WOOQO,5:W,5:WOOQO-E7j-E7jOOQO-E7h-E7hOOQO-E7y-E7yOOQO<<Hh<<Hh",
  stateData: "6^~OfOS!wOS~OSeOUiOVVOWWOXYOY[OZ_O]aO_cOaTOdgOjRO!xPO~O[oO!yqOj!_X!u!_X!x!_XU!_X~OjsO!u!VX!x!VXU!VX~OaTO!uuX!xuXUuX~OjxOmwO#QyO#UzO~OWWOjxOmwO~OXYOjxOmwO~OY[OjxOmwO~OZ_OjxOmwO!u{X!x{XU{X~O]aOj!]O~O_cOj!_O~OSeOj!aO~OdgOj!eOm!eO~OUiOVVOWWOXYOY[OZ_O]aO_cOaTOdgOjRO~O!xPO~P%aOSeO~P%aOP!sO[oOj!oOm!nO~OP!sOj!oOm!nO~O!{!uO!|!uO!}!vO#O!vOj!bXm!bX!u!bX!x!bX#Q!bX#U!bXU!bX~OQ!yOjlXmlX!ulX!xlX!{lX!|lX!}lX#OlXUlX~O#QlX#UlX~P'rO#R!{O#S!}O~O#V#OO#W#QO~OjxOmwO#QyO#UzO!uva!xvaUva~O!{!uO!|!uO!}!vO#O!vO!uxa!xxaUxa~O!{!uO!|!uO!}!vO#O!vO!uya!xyaUya~O!{!uO!|!uO!}!vO#O!vO!uza!xzaUza~O!{!uO!|!uO!}!vO#O!vO!u{a!x{aU{a~O`#SO~O^#UO~O[oOb#XOc#ZO~Oe#_O~O!xPOS!YaU!YaV!YaW!YaX!YaY!YaZ!Ya]!Ya_!Yaa!Yad!Yaj!Ya!u!Ya~O!{!uO!|!uO!}!vO#O!vOj![Xm![X!u![X!x![XU![X~Or#bO~P'uOj!_Xr!_X~P'rOjxOmwO!uii!xiiUii~OjsOr#bO~OP!sOjxOmwO#QyO#UzO~OjwOmwO~OQ!yOR#jOj#lOm#lO~O#R!{O#S#nO~O#V#OO#W#pO~O`#SOj#rO~O^#UOj#tO~OT#vO[oOjwOmwO#QyO#UzO~Ob#XOj#zO~Oc#ZOj#|O~OUiO!u!Oi!x!Oi~OSeOe#_O!u!Ui!x!Ui~P%aOjsOm$SO~Or#bO!uqq!xqqUqq~OjxOmwO#QyO#UzO!usq!xsqUsq~O!{!uO!|!uO!}!vO#O!vOj!zim!zi!u!zi!x!zi#Q!zi#U!ziV!ziW!ziX!ziY!ziZ!zi]!zi_!zia!zid!ziU!zi~OR#jOjpimpi!upi!xpi#Qpi#UpiUpi~OV!QiW!QiX!QiY!QiZ!Qi]!Qi_!Qia!Qid!Qij!Qi!x!Qi~O!{!uO!|!uO!}!vO#O!vO~P4aOT#vO~P4aOUiO!u!Oq!x!Oq~OjsOr!`a!u!`a!x!`aU!`a~Omj~",
  goto: ",z#UPPPPPPPPPPPPPPPPPPPPPPPP#V#_P#k#nP$`$j$t#_P#_%]#_#_#_%f%f%f#_#_#_%p%v%y%y%y%|#_#_&Y&f&x'P'Z'b'i'o(V(^(e(o(u({)Y)g)t*R*`*m*s+Q+W+a+g+m+s,Q,WPPPPP,cPPPP,sPPP,sUlOQmR$Q#`WkOQm#`X!gj!c!f#]RrRWvV}!t#fQ!OXQ!RZQ!U]Q!X`U!mpr!qQ#g!wQ#h!xR#u#Wa!wv!O!R!U!X!m#h#ua!xv!O!R!U!X!m#h#uW|V}!t#fQ!QXQ!TZQ!W]Q!Z`V!ppr!qW|V}!t#fR#x#Wa^OQjm!c!f#]#`XkOQm#`R!cfR!bfQ!ijQ#^!cQ#a!fR#}#]WkOQm#`Q$O#^R$W#}QQOWnQ!f!j#]Q!fjQ!jlR#]!cSmOQR!kmQpRS!lp#WR#W!aS!qprR#d!qS!zx!oR#i!zQ#k!zR$U#k`SOQjm!c!f#]#`UtS!r$RS!rprR$R#bS#c!n!rR$T#cS!tprR#e!tQ}VS#R}#fR#f!tQ!|yR#m!|Q#PzR#o#P`UOQjm!c!f#]#`RuU`XOQjm!c!f#]#`R!PX`ZOQjm!c!f#]#`R!SZ`]OQjm!c!f#]#`R!V]``OQjm!c!f#]#`R!Y``bOQjm!c!f#]#`R![bQ#T!]R#q#T`dOQjm!c!f#]#`R!^dQ#V!_R#s#VWfOQm#`R!`fQ#w#WR$V#wQ#Y!aR#y#YQ#[!aR#{#[`hOQjm!c!f#]#`R!dhQ#`!eR$P#`[jOQm#^#`#}R!hjmwVXZ]`pr}!q!t!w!x#W#fZ{V}!t#W#f",
  nodeNames: "⚠ ask at random if pressed else print forward turn color sleep is add from remove toList clear in not_in repeat times Comment Program Command Assign Text Op Expression Int Op Op ListAccess AssignList Comma Ask String Clear Print Turtle Forward Turn Color Sleep Add Remove If Condition EqualityCheck InListCheck NotInListCheck IfLessCommand Repeat ErrorInvalid Else",
  maxTerm: 100,
  nodeProps: [
    ["group", 39,"turtle"]
  ],
  skippedNodes: [0,22],
  repeatNodeCount: 28,
  tokenData: "*x~R!^OY$}YZ&UZp$}pq&Zqr$}rs&`st&etw$}wx&|xz$}z{'R{|'W|}']}!O'b!O!P$}!P!Q'g!Q!R'l!R!S'l!S!T'l!T!U'l!U!V'l!V!W'l!W!X'l!X!Y'l!Y!Z'l!Z!['l![!_$}!_!`*s!`#Q$}#RBn$}BnBo']BoDf$}DfDg'lDgDh'lDhDi'lDiDj'lDjDk'lDkDl'lDlDm'lDmDn'lDnDo'lDoDp'lDpGl$}GlGm'lGmGn'lGnGo'lGoGp'lGpGq'lGqGr'lGrGs'lGsGt'lGtGu'lGuGv'lGv&FV$}&FV&FW']&FW;'S$};'S;=`&O<%l?Hb$}?Hb?Hc']?HcO$}~%S^j~OY$}Zp$}qr$}tw$}xz$}!O!P$}!Q!_$}!`#Q$}#RBn$}Bo&FV$}&FW;'S$};'S;=`&O<%l?Hb$}?HcO$}~&RP;=`<%l$}~&ZO!x~~&`O!w~~&eO#Q~~&jSf~OY&eZ;'S&e;'S;=`&v<%lO&e~&yP;=`<%l&e~'RO#U~~'WO!{~~']O!}~~'bOr~~'gO#O~~'lO!|~~'s!Om~j~OY$}Zp$}qr$}tw$}xz$}!O!P$}!Q!R'l!R!S'l!S!T'l!T!U'l!U!V'l!V!W'l!W!X'l!X!Y'l!Y!Z'l!Z!['l![!_$}!`#Q$}#RBn$}BoDf$}DfDg'lDgDh'lDhDi'lDiDj'lDjDk'lDkDl'lDlDm'lDmDn'lDnDo'lDoDp'lDpGl$}GlGm'lGmGn'lGnGo'lGoGp'lGpGq'lGqGr'lGrGs'lGsGt'lGtGu'lGuGv'lGv&FV$}&FW;'S$};'S;=`&O<%l?Hb$}?HcO$}~*xO!y~",
  tokenizers: [2, new LocalTokenGroup("_~RQYZXwxX~^O#W~~", 14, 99), new LocalTokenGroup("_~RQYZXrsX~^O#S~~", 14, 95)],
  topRules: {"Program":[0,23]},
  dynamicPrecedences: {"53":-10},
  specialized: [{term: 26, get: (value: any, stack: any) => (specializeKeyword(value, stack) << 1), external: specializeKeyword},{term: 26, get: (value: any, stack: any) => (extendKeyword(value, stack) << 1) | 1, external: extendKeyword, extend: true}],
  tokenPrec: 977
})
