// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  elses = 5,
  pressed = 6,
  print = 7,
  forward = 8,
  turn = 9,
  color = 10,
  sleep = 11,
  is = 12,
  add = 13,
  from = 14,
  remove = 15,
  toList = 16,
  clear = 17,
  ins = 18,
  not_in = 19,
  Comment = 20,
  Program = 21,
  Command = 22,
  Assign = 23,
  Text = 24,
  Expression = 26,
  Int = 27,
  ListAccess = 30,
  AssignList = 31,
  Comma = 32,
  Ask = 33,
  String = 34,
  Clear = 35,
  Print = 36,
  Turtle = 37,
  Sleep = 41,
  Add = 42,
  Remove = 43,
  If = 44,
  Condition = 45,
  IfLessCommand = 49,
  ErrorInvalid = 50,
  Else = 51
