// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  at = 2,
  random = 3,
  ifs = 4,
  elses = 5,
  pressed = 6,
  print = 7,
  forward = 8,
  turn = 9,
  color = 10,
  sleep = 11,
  is = 12,
  add = 13,
  from = 14,
  remove = 15,
  toList = 16,
  clear = 17,
  ins = 18,
  not_in = 19,
  Comment = 20,
  Program = 21,
  Command = 22,
  Assign = 23,
  Text = 24,
  ListAccess = 25,
  AssignList = 26,
  Comma = 27,
  Ask = 28,
  String = 29,
  Clear = 30,
  Print = 31,
  Turtle = 32,
  Sleep = 36,
  Add = 37,
  Remove = 38,
  If = 39,
  Condition = 40,
  IfLessCommand = 44,
  ErrorInvalid = 45,
  Else = 46
