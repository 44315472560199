// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  ask = 1,
  random = 2,
  ifs = 3,
  pressed = 4,
  elses = 5,
  and = 6,
  or = 7,
  is = 8,
  _while = 9,
  elif = 10,
  print = 11,
  forward = 12,
  turn = 13,
  color = 14,
  sleep = 15,
  add = 16,
  from = 17,
  remove = 18,
  toList = 19,
  clear = 20,
  ins = 21,
  not_in = 22,
  repeat = 23,
  times = 24,
  fors = 25,
  to = 26,
  range = 27,
  call = 28,
  define = 29,
  returns = 30,
  _with = 31,
  Comment = 32,
  Program = 33,
  Command = 34,
  Assign = 35,
  Text = 36,
  ListAccess = 37,
  Number = 39,
  Expression = 42,
  String = 43,
  AssignList = 46,
  Comma = 47,
  Ask = 48,
  Clear = 49,
  Print = 50,
  Call = 51,
  Arguments = 52,
  Turtle = 53,
  Sleep = 57,
  Add = 58,
  Remove = 59,
  If = 60,
  Condition = 61,
  Else = 72,
  Repeat = 73,
  For = 74,
  Define = 75,
  Return = 76,
  While = 77,
  Elif = 78,
  ErrorInvalid = 79
